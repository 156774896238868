import {
  Alignment,
  CheckoutConfiguration,
  CustomerPortalConfiguration,
  FontWeight as JSFontWeight,
  PaywallConfiguration,
  TypographyConfiguration,
} from '@stigg/js-client-sdk';
import { CustomizedTheme } from '../../theme/Theme';
import { HorizontalAlignment, FontWeight } from '../../theme/types';
import { defaultCheckoutTypography } from '../checkout/configurations/typography';

function addCssUnits(value?: number | null, unit = 'px') {
  if (!value) {
    return undefined;
  }
  return `${value}${unit}`;
}

function mapFontWeight(defaultValue: FontWeight, fontWeight?: JSFontWeight | null): FontWeight {
  if (!fontWeight) {
    return defaultValue;
  }

  switch (fontWeight) {
    case JSFontWeight.Normal:
      return 'normal';
    case JSFontWeight.Bold:
      return 'bold';
    default:
      return 'normal';
  }
}

export function mapTypography(
  typography?: TypographyConfiguration | null,
  defaultTypography?: TypographyConfiguration | null,
): CustomizedTheme['typography'] {
  return {
    fontFamilyUrl: typography?.fontFamily || undefined,
    h1: {
      fontSize: addCssUnits(typography?.h1?.fontSize || defaultTypography?.h1?.fontSize),
      fontWeight: mapFontWeight('bold', typography?.h1?.fontWeight || defaultTypography?.h1?.fontWeight),
    },
    h2: {
      fontSize: addCssUnits(typography?.h2?.fontSize || defaultTypography?.h2?.fontSize),
      fontWeight: mapFontWeight('normal', defaultTypography?.h2?.fontWeight),
    },
    h3: {
      fontSize: addCssUnits(typography?.h3?.fontSize || defaultTypography?.h3?.fontSize),
      fontWeight: mapFontWeight('normal', typography?.h3?.fontWeight || defaultTypography?.h3?.fontWeight),
    },
    body: {
      fontSize: addCssUnits(typography?.body?.fontSize || defaultTypography?.body?.fontSize),
      fontWeight: mapFontWeight('normal', typography?.body?.fontWeight || defaultTypography?.body?.fontWeight),
    },
  };
}

function mapAlignment(alignment?: Alignment | null): HorizontalAlignment | undefined {
  if (!alignment) {
    return undefined;
  }

  switch (alignment) {
    case Alignment.Left:
      return 'left';
    case Alignment.Center:
      return 'center';
    case Alignment.Right:
      return 'right';
    default: {
      return undefined;
    }
  }
}

export function mapCustomerPortalConfiguration(configuration: CustomerPortalConfiguration): CustomizedTheme {
  const { palette, typography } = configuration;
  return {
    palette: {
      primary: palette?.primary || undefined,
      backgroundPaper: palette?.paywallBackgroundColor || undefined,
      outlinedBorder: palette?.borderColor || undefined,
      backgroundHighlight: palette?.currentPlanBackground || undefined,
      text: {
        primary: palette?.textColor || undefined,
      },
    },
    typography: mapTypography(typography),
  };
}

export function mapPaywallConfiguration(paywallConfiguration: PaywallConfiguration): CustomizedTheme {
  const { palette, layout, customCss, typography } = paywallConfiguration;
  return {
    customCss: customCss || undefined,
    palette: {
      primary: palette?.primary || undefined,
      backgroundPaper: palette?.backgroundColor || undefined,
      outlinedBorder: palette?.borderColor || undefined,
      backgroundHighlight: palette?.currentPlanBackground || undefined,
      text: {
        primary: palette?.textColor || undefined,
      },
    },
    typography: mapTypography(typography),
    layout: {
      ctaAlignment: mapAlignment(layout?.alignment),
      headerAlignment: mapAlignment(layout?.alignment),
      descriptionAlignment: mapAlignment(layout?.alignment),
      planMaxWidth: addCssUnits(layout?.planWidth),
      planMinWidth: addCssUnits(layout?.planWidth),
      planMargin: addCssUnits(layout?.planMargin),
      planPadding: addCssUnits(layout?.planPadding),
    },
  };
}

export function mapCheckoutConfiguration(configuration: CheckoutConfiguration): CustomizedTheme {
  const { palette, typography } = configuration;
  return {
    palette: {
      primary: palette?.primary || undefined,
      backgroundPaper: palette?.backgroundColor || undefined,
      outlinedBorder: palette?.borderColor || undefined,
      text: {
        primary: palette?.textColor || undefined,
      },
      backgroundHighlight: palette?.summaryBackgroundColor || undefined,
    },
    typography: mapTypography(typography, defaultCheckoutTypography),
  };
}
