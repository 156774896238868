import { CustomerPortalEntitlement } from '@stigg/js-client-sdk';
import moment from 'moment';

export const USAGE_PERCENTAGE_WARNING_THRESHOLD = 70;

export function getUsagePercentage(currentUsage: number, limit: number) {
  return Math.min(Math.floor((currentUsage / limit) * 100), 100);
}

export function getUsageProgressStatus(currentUsage: number) {
  let status: 'standard' | 'warning' | 'error';

  if (currentUsage < USAGE_PERCENTAGE_WARNING_THRESHOLD) {
    status = 'standard';
  } else if (currentUsage < 100) {
    status = 'warning';
  } else {
    status = 'error';
  }

  return status;
}

export function getResetUsageText(entitlement: CustomerPortalEntitlement) {
  if (!entitlement.resetPeriod || !entitlement.nextResetDate) {
    return null;
  }

  const calculateResetDiff = (unit: 'days' | 'hours' | 'minutes') => {
    return moment.utc(entitlement.nextResetDate).diff(moment.utc(), unit);
  };

  switch (entitlement.resetPeriod) {
    case 'MONTH':
      return `Resets monthly - in ${calculateResetDiff('days')} days`;
    case 'WEEK':
      return `Resets weekly - in ${calculateResetDiff('days')} days`;
    case 'DAY':
      return `Resets daily - in ${calculateResetDiff('hours')} hours`;
    case 'HOUR':
      return `Resets hourly - in ${calculateResetDiff('minutes')} minutes`;
    default:
      return null;
  }
}
